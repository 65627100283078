<template>
  <v-row justify="center">
    <div>
       Neki kod {{ code2 }}
       <v-img
        :aspect-ratio="1"
        class="bg-white"
        :src="Canvas1"
        width="200"
        height="100"
      ></v-img>
    </div>
  </v-row>
</template>


<script>
import generateBarcode from "pdf417"

export default {
  name: '',
  props: ['vir'],
  data: ()=> ({
    dialog: true,
    code2: null,
    blockWidth: 4,
    blockHeight: 2,
    Canvas1: null
  }),
  created () {
    console.log('vir', this.vir)
    this.add2Dcode()
  },
  methods: {
    add2Dcode() {
      const strIznos = this.vir.Iznos.toString()
      const decPart = strIznos.substring(strIznos.length - 2)
      const numPart = parseInt(this.vir.Iznos).toString()
      const numLength = numPart.length + 2
      const fullNum = '0'.repeat(15 - numLength) + numPart + decPart
      this.code2 = "HRVHUB30\nEUR\n" +
        fullNum + "\n"+
        (this.vir.Primatelj1 ? this.vir.Primatelj1: ' ') + "\n"+
        (this.vir.Primatelj2 ? this.vir.Primatelj2 : ' ') + '' + "\n"+
        (this.vir.Primatelj3 ? this.vir.Primatelj3 : ' ') + '' + "\n"+
        this.vir.Platitelj1 + "\n"+
        (this.vir.Platitelj2 ? this.vir.Platitelj2 : ' ') + ' ' + "\n"+
        (this.vir.Platitelj3 ? this.vir.Platitelj3 : ' ') + ' ' + "\n"+
        (this.vir.PrimateljIBAN ? this.vir.PrimateljIBAN : ' ') + "\n"+
        "HR02\n"+
        this.vir.Opis1 + "\n" + this.vir.Opis2 + "\n"+
        "COST\n"+
        this.locDocumentName + "\n"
        this.Canvas1 = generateBarcode(this.code2, 4, 2)
    },
  }
  

}

</script>