<template>
<div>
  <v-container fluid grid-list-md>

  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.BankOrder}},  Ukupno: {{localMoney(total)}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          v-model="selectedBatch"
          :items="orderBatch"
          label="Broj grupe u danu"
          class="mt-4"
        >
        </v-select>
         
          <v-btn
              color="blue"
              text
              @click="preparebo"
            >
              Pripremi datoteku
          </v-btn>
          <v-btn
              color="blue"
              text
              @click="downloadbo"
            >
              Download
          </v-btn>
          <v-btn
              color="blue"
              text
              @click="bankorderprnt"
            >
              PRINT
            </v-btn>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
           

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Platitelj1"
                        v-model="editedItem.Platitelj1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.Platitelj2"
                        v-model="editedItem.Platitelj2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.PlatiteljIBAN"
                        v-model="editedItem.PlatiteljIBAN"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PlatiteljModel"
                        v-model="editedItem.PlatiteljModel"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PlatiteljPoziv"
                        v-model="editedItem.PlatiteljPoziv"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Opis1"
                        v-model="editedItem.Opis1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.Opis2"
                        v-model="editedItem.Opis2"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Iznos"
                        v-model.number="editedItem.Iznos"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Primatelj1"
                        v-model="editedItem.Primatelj1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.Primatelj1"
                        v-model="editedItem.Primatelj2"
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.PrimateljIBAN"
                        v-model="editedItem.PrimateljIBAN"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PrimateljModel"
                        v-model="editedItem.PrimateljModel"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PrimateljPoziv"
                        v-model="editedItem.PrimateljPoziv"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                   <v-row>
                     <v-col cols="12" xs="6" sm="6" md="4" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.Grupa"
                        v-model.number="editedItem.Grupa"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="pr-1">
                      Datum Izvršenja: <input type="date" v-model="editedItem.DatumIzvrsenja" />
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                        v-model="doSepa"
                        label="Plati"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  
                  <bank-payment-code :vir="editedItem" />

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.Iznos`]="{ item }"> 
      {{ localMoney(item.Iznos) }}
    </template>
    <template v-slot:[`item.DatumIzvrsenja`]="{ item }">
        <span>{{ localDate(item.DatumIzvrsenja) }}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.EmptyBankOrder }}
    </template>
  </v-data-table>
  <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

  <BankOrderPrint ref="prnt" :card="card" />
  </v-container>
  </div>
</template>

<script>
import BankOrderService from '@/services/BankOrderService'
import BankOrderPrint from './BankOrderPrint.vue'
import langEn from './bankorderDescEn'
import langHr from './bankorderDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import FileSaver from 'file-saver'
import BankPaymentCode from '../../snippets/bankPaymentCode.vue'

  export default {
    name: 'bankorderCRUD',
    props: ['virmanFor'],
    data: () => ({
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      card: {},
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {},
      dialog: false,
      headers: [],
      users: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        UserId: null,
        EmployeeId: 0,
        email: '',
        First: '',
        Last: '',
        salarieID: 0,
        Order: 0,
        Month: 0,
        Year: 0,
        Grupa: 0,
        Hitno: false,
        ValutaPlacanja1: '',
        Tecaj: 7.543,
        ValutaPlacanja2: '',
        Platitelj1: '',
        Platitelj2: '',
        Platitelj3: '',
        PlatiteljIBAN: '',
        PlatiteljModel: '',
        PlatiteljPoziv: '',
        Opis1: '',
        Opis2: '',
        Opis3: '',
        Iznos: 0,
        Iznos2: 0,
        Primatelj1: '',
        Primatelj2: '',
        Primatelj3: '',
        PrimateljIBAN: '',
        PrimateljModel: '',
        PrimateljPoziv: '',
        SifraNamjene: '',
        DatumIzvrsenja: null,
        BrojJoppdid: 0,
        BrojJoppd: '',
        bankAccountId: '',
        bankAccountName: '',
        bankAccountNr: '',
        LastUpdateBy: '',
        LastUpdate: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: '',
        FreeF6: '',
      },
      privitems: [],
      valid: true,
      total: 0,
      doSepa: false,
      selectedBatch: 1,
      orderBatch: [1,2,3,4,5,6,7,8,9,10]
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewBankOrder: this.lang.EditBankOrder
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    components: {
      BankOrderPrint,
        BankPaymentCode
    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.virmanFor === 'salarie') {
        this.mainQuery.salarieID = this.$store.state.documentActivHead.id
      }
      if (this.virmanFor === 'topay') {
        this.mainQuery.FreeF5 = 'URA'
      }     

      this.headers.push(
        {text: 'Sa', value: 'PlatiteljModel', width: '50px'},
        {text: 'Poziv', value: 'PlatiteljPoziv', width: '200px'},
        {text: this.lang.Opis1, value: 'Opis1', width: '200px'},
        {text: this.lang.Iznos, align: 'right',value: 'Iznos', width: '100px'},
        {text: this.lang.Primatelj1, value: 'Primatelj1', width: '100px'},
        {text: this.lang.PrimateljIBAN, value: 'PrimateljIBAN', width: '100px'},
        {text: 'Na', value: 'PrimateljModel', width: '50px'},
        {text: 'Poziv', value: 'PrimateljPoziv', width: '200px'},
        {text: this.lang.Grupa, value: 'Grupa', width: '100px'},
        {text: this.lang.DatumIzvrsenja, value: 'DatumIzvrsenja', width: '100px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
      this.initialize()
    },

    created () {

    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      localMoney(moneyToFormat) {
        return moneyFormat(moneyToFormat)
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },


      async initialize () {
        try {
          this.saving = true
          const {data} = await BankOrderService.index(this.mainQuery)
          if (data && data.bankorders !== undefined){
            this.users = data.bankorders
            this.users.map(usr => {
              this.total += (usr.Iznos ? usr.Iznos : 0)
            })
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      async preparebo() {
        try {
          const forBank = {}
          const toGo = this.users.filter(user => parseInt(user.FreeF2) === 1)
          forBank.items = [...toGo]
          forBank.head = {}
          forBank.head.VATID = this.$store.state.companyActiv.CompanyVATID? this.$store.state.companyActiv.CompanyVATID: ''
          forBank.head.Order = this.users[0].Order
          forBank.head.Month = this.users[0].Month
          forBank.head.Year = this.users[0].Year
          forBank.head.CompanyNameDatabase = this.users[0].CompanyNameDatabase
          forBank.head.PlatiteljIBAN = this.users[0].PlatiteljIBAN
          forBank.head.DatumIzvrsenja = this.users[0].DatumIzvrsenja
          forBank.head.selectedBatch = this.selectedBatch ? this.selectedBatch : 1

          await BankOrderService.createboform(forBank)
          this.text = 'Datoteka sa virmanima je kreirana.'
          this.color = 'green'
          this.snackbar = true
          this.timeout = 2000
        } 
        catch(err) {
          this.error = err
          this.text = 'Greska!! Datoteka sa virmanima NIJE kreirana.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        }
      
      },
      async downloadbo () {
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
        this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.mainQuery.VATID = this.$store.state.companyActiv.CompanyVATID? this.$store.state.companyActiv.CompanyVATID: ''
        this.mainQuery.Order = this.users[0].Order
        this.mainQuery.month = this.users[0].Month
        this.mainQuery.year = this.users[0].Year
        this.mainQuery.DatumIzvrsenja = this.users[0].DatumIzvrsenja
        const fromDate = new Date(this.users[0].DatumIzvrsenja.substring(0,10))
        const msgid = 'UN' + fromDate.getFullYear() + ((fromDate.getMonth() + 1).toString().length === 1 ? '0' + (fromDate.getMonth() + 1).toString() :  (fromDate.getMonth()).toString()) + ((fromDate.getDate()).toString().length === 1 ? '0' + (fromDate.getDate()).toString() :  (fromDate.getDate()).toString()) + '0001'

        const zoveSe = msgid + '.xml'
        BankOrderService.uploadboform(this.mainQuery)
        .then(res => {
          const data = res.data
          //const status = res.status
          const header = res.headers
          const fileType = header['content-type']
          const blob = new Blob([data], { type: fileType })
          FileSaver.saveAs(blob, zoveSe);
          //FileSaver.saveAs(res.data, zoveSe)
          // this.text = 'Spremite knjigu.'
          // this.color = 'green'
          // this.snackbar = true
          // this.timeout = 2000
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          this.text = 'Greska!! Obrazac se NE moze ucitati.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      },
      async bankorderprnt () {
        const newObj = {}
        this.card = {...newObj}
        this.card = {...this.$store.state.documentActivHead}
        this.card.head1 = this.users ? this.users : []
        this.card.head2 = ''
        this.card.head3 = null
        this.card.head4 = null
        this.card.head5 = null
        await this.$store.dispatch('setPrintObj', this.card)
        await this.$refs.prnt.print()
      },
      editItem (item) {
        this.errorMatch = false
        this.doSepa = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (this.editedItem.FreeF2 === 1) {
          this.doSepa = true
        }
        this.dialog = true
        this.confpassword = ''
      },
      async deleteItem (item) {
        try {
          
          const index = this.users.indexOf(item)
          const warningMessage = item.CityName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await BankOrderService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              this.editedItem.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.editedItem.LastUpdate = new Date()
              await BankOrderService.put(this.editedItem)
              doneOK = true
            } else {
              if (this.doSepa === true) {
                this.editItem.FreeF2 = 1
              }
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.salarieID = this.$store.state.documentActivHead.id
              this.editedItem.Order = this.$store.state.documentActivHead.Order
              this.editedItem.Month = this.$store.state.documentActivHead.Month
              this.editedItem.Year = this.$store.state.documentActivHead.Year
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.editedItem.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
              this.editedItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
              await BankOrderService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      
    },
  }
</script>